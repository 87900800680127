import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import DoneImg from "../../assets/images/woman-done.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"

  const Content = styled.section`
    width: 100%;
    max-width: 68.75rem;
    max-height: calc(100vh - 13.25rem);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 3rem 0 3.75rem;
    gap: 1.375rem;

    @media screen and (max-width: 1020px) {
      padding: 1rem 2.5rem;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    
    }
  `

  const ContentInfos = styled.div`
    max-width: 540px;
  `

  const ImageWrapper = styled.div`
    position: relative;
    max-width: 475px;
    max-height: 392px;
  `

  const Image = styled(DoneImg)`
    max-width: 23.1875rem;
    max-height: 25.0625rem;
    position: relative;
  `

  const ImageCredit = styled.span`
    color: #515151;
    font-size: 0.675rem;
    position: absolute;
    bottom: 1rem;
    right: 2rem;

    a{
      text-decoration: none;
    }
  `

  const ImageCreditLink = styled.a`
    text-decoration: underline;
  `

  const Heading = styled.div`
    width: 100%;
    color: #1f1f1f;
    font-family: "Nunito";
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  `

  const Text = styled.p`
    font-family: "Nunito";
    font-weight: 400;
    font-size: 1.5rem;
    color: #1f1f1f;
    margin-bottom: 3rem;
  `
 
  
export default function FinalizarCadastroAtendimento() {

  return (
    <>
      <Header />
      <Content>
        <ContentInfos>
          <Heading>Agradecemos sua inscrição</Heading>
          <Text>
          Para segurança da Lacrei Saúde, iremos verificar seus dados e em breve você receberá um link de confirmação em seu e-mail.
          </Text>
          <Button link="/" maxWidth="12" title="Voltar ao painel" />
        </ContentInfos>
        <ImageWrapper>
          <Image />
          <ImageCredit>
            Ilustrado por{" "}
            <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
              Freepik
            </ImageCreditLink>
          </ImageCredit>
        </ImageWrapper>
      </Content>
      <Footer />
    </>
  )
}
